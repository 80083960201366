import React, { Suspense, lazy, useState, useEffect } from "react";
import Loader from "./components/Loader";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useNavigate,
  useHistory
} from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import ErrorPage from "./components/ErrorPage";
import ErrorBoundary from "./components/ErrorPage/ErrorBoundary";
import { useDispatch, useSelector } from "react-redux";
import "./App.scss";

import Sidebar from "./components/Sidebar";
import ContextContainer from "./hooks/ContextContainer/ContextContainer";
import RenderOnAnonymous from "./redux/RenderOnAnonymous";
import RenderOnAuthenticated from "./redux/RenderOnAuthenticated";
//NSWS-4114
import useIsMobile from "./components/TextileVerification/isMobile";

const Login = lazy(() =>
  import(/* webpackChunkName: "Login" */ "./components/Login")
);

const Excelupload = lazy(() =>
  import(/* webpackChunkName: "Login" */ "./components/Excelupload")
);

const LicenseInsertion = lazy(() =>
  import(/* webpackChunkName: "Login" */ "./components/LicenseInsertion")
);

const Dashboard = lazy(() =>
  import(/* webpackChunkName: "Dashboard" */ "./components/Dashboard")
);

const WorkItem = lazy(() =>
  import(/* webpackChunkName: "WorkItem" */ "./components/WorkItem")
);

const Application = lazy(() =>
  import(/* webpackChunkName: "Application" */ "./components/Application")
);

const Forbidden = lazy(() =>
  import(/* webpackChunkName: "Forbidden" */ "./components/Forbidden")
);

const Header = lazy(() =>
  import(/* webpackChunkName: "Header" */ "./components/Header")
);
const Header1 = lazy(() =>
  import(/* webpackChunkName: "Header1" */ "./components/Header1")
);

const Contact = lazy(() =>
  import(/* webpackChunkName: "Contact" */ "./components/Contact")
);

const FAQ = lazy(() =>
  import(/* webpackChunkName: "Contact" */ "./components/FAQ")
);

const Terms = lazy(() =>
  import(/* webpackChunkName: "Terms" */ "./components/Terms")
);

const Policy = lazy(() =>
  import(/* webpackChunkName: "Policy" */ "./components/Policy")
);
const Preview = lazy(() =>
  import(/*webpackChunkName:"Preview" */ "./components/Preview")
);
const Footer = lazy(() =>
  import(/* webpackChunkName: "Footer" */ "./components/Footer")
);

const UserCreation = lazy(() =>
  import(/* webpackChunkName: "UserCreation" */ "./components/UserCreation")
);
const UserManage = lazy(() =>
  import(/* webpackChunkName: "UserManage" */ "./components/UserManage")
);
const PaymentReport = lazy(() =>
  import(/* webpackChunkName: "UserManage" */ "./components/PaymentReport")
);
const WorkflowCreation = lazy(() =>
  import(
    /* webpackChunkName: "WorkflowCreation" */ "./components/WorkflowCreation"
  )
);
const Logout = lazy(() =>
  import(/* webpackChunkName: "Logout" */ "./components/Logout")
);

const WorkflowView = lazy(() =>
  import(
    /* webpackChunkName: "WorkflowView" */ "./components/WorkflowView"
  )
);


const Department = lazy(() =>
  import(/* webpackChunkName : Department */ "./components/Department")
);
const MyDetail = lazy(() =>
  import(/* webpackChunkName : Department */ "./components/MyDetail")
);
const Report = lazy(() =>
  import(/* webpackChunkName : Department */ "./components/Report")
);
const WorkflowManagement = lazy(() =>
  import(
    /* webpackChunkName : WorkFlowManagement */ "./components/WorkflowManagement"
  )
);
const SplitDocument = lazy(() =>
  import(
    /* webpackChunkName : WorkFlowManagement */ "./components/SplitDocument"
  )
);
const PaymentGateway = lazy(() =>
  import(/* webpackChunkName : PaymentGateway */ "./components/PaymentGateway")
);

// const RegisterDSC = lazy(
//   () =>
//     import(/* webpackChunkName : PaymentGateway */ "./components/InvestorDscRegister/dependency/RegisterDSC")
// )

const RegisterDSC = lazy(() =>
  import(
    /* webpackChunkName : PaymentGateway */ "./TTN/InvestorDscRegister/dependency/RegisterDSC"
  )
);

const Ministry = lazy(() =>
  import(/* webpackChunkName : Department */ "./components/Ministry")
);

const ComplianceReport = lazy(() =>
  import(
    /* webpackChunkName : ComplianceReport*/ "./components/ComplianceReport"
  )
);

const SwitchRole = lazy(() =>
  import(/* webpackChunkName : Department */ "./components/SwitchRole")
);

const Task1 = lazy(() =>
  import(/* webpackChunkName : Task */ "./components/Task")
);

//NSWS-4114

const TextileVerification = lazy(() =>
  import(/* webpackChunkName : Department */ "./components/TextileVerification")
);

const TextileVerificationCapture = lazy(() =>
  import(/* webpackChunkName : Department */ "./components/TextileVerification/capture.js")
);

const TextileApplication = lazy(() =>
  import(/* webpackChunkName : Department */ "./components/TextileVerification/application.js")
);



function App(props) {
  const dispatch = useDispatch();
  //NSWS-4114
  const isMobile = useIsMobile();
  const userProfile = useSelector((state) => state.user.userProfile);
  console.log(isMobile, window.location.pathname, userProfile, "is Mobile");

  const module = useSelector((state) => state.modulesRoleId.moduleRoleId);
  const isApiLoading = useSelector((state) => state.Common.isApiLoading);

  const [roleList, setRoleList] = useState([])
  const [roleId, setRoleId] = useState(null)
  const [isRoleIdSelected, setIsRoleIdSelected] = useState(false)
  useEffect(() => {
    console.log("roleId, dashboard", roleId, module)
    let retrievedRoleId = localStorage.getItem("roleId")
    // if (roleId === "" || roleId === null) {
    if (retrievedRoleId !== null) {
      // setRoleId(JSON.parse(retrievedRoleId))
      dispatch({
        type: "SET_MODULEROLEID_DATA",
        payload: {
          moduleRoleId: JSON.parse(retrievedRoleId),
        },
      })
    }
    console.log("roleId, dashboard last", roleId, module)
  }, [roleId])
  useEffect(() => {
    console.log(isRoleIdSelected, "Selected isroleidselected", window.location.pathname)
  }, [isRoleIdSelected])

  return (
    <Router>
      {isApiLoading && <Loader />}
      <Suspense fallback={<Loader />}>
        <div>
          <RenderOnAnonymous>
            <Switch>
              <Route
                path="/login"
                render={(props) => <Login {...props} />}
                children={
                  <ErrorBoundary>
                    <Login />
                  </ErrorBoundary>
                }
              />
              <Route exact path="/" render={() => <Redirect to="/login" />} />
              <Route
                path="/contact"
                render={(props) => <Contact {...props} />}
                children={
                  <ErrorBoundary>
                    <Contact />
                  </ErrorBoundary>
                }
              />
              <Route
                path="/FAQ"
                render={(props) => <FAQ {...props} />}
                children={
                  <ErrorBoundary>
                    <FAQ />
                  </ErrorBoundary>
                }
              />
              <Route
                path="/Terms"
                render={(props) => <Terms {...props} />}
                children={
                  <ErrorBoundary>
                    <Terms />
                  </ErrorBoundary>
                }
              />
              <Route
                path="/Policy"
                render={(props) => <Policy {...props} />}
                children={
                  <ErrorBoundary>
                    <Policy />
                  </ErrorBoundary>
                }
              />

              <Route
                path="/logout"
                render={(props) => <Logout {...props} />}
                children={
                  <ErrorBoundary>
                    <Logout />
                  </ErrorBoundary>
                }
              />

              <Route
                path="/paymentgateway/:id"
                render={(props) => <PaymentGateway {...props} />}
                children={
                  <ErrorBoundary>
                    <PaymentGateway />
                  </ErrorBoundary>
                }
              />

              {/* <Route
                path="/investorDSC/:id"
                render={(props) => <RegisterDSC {...props} />}
                children={
                  <ErrorBoundary>
                    <RegisterDSC />
                  </ErrorBoundary>
                }
              /> */}
            </Switch>
          </RenderOnAnonymous>
          <RenderOnAuthenticated>
            <Switch>
              <Route
                path="/paymentgateway/:id"
                render={(props) => <PaymentGateway {...props} />}
                children={
                  <ErrorBoundary>
                    <PaymentGateway />
                  </ErrorBoundary>
                }
              />
              <Route
                path="/investorDSC/:id"
                render={(props) => <RegisterDSC {...props} />}
                children={
                  <ErrorBoundary>
                    <RegisterDSC />
                  </ErrorBoundary>
                }
              />
              {/* NSWS-4114 */}

              {
                isMobile && localStorage.getItem("textileMinistryId") === "M034" &&
                <Route
                  path="/workitem-sm/capture"
                  render={(props) => <TextileVerificationCapture {...props} />}
                  children={
                    <ErrorBoundary>
                      <TextileVerificationCapture />
                    </ErrorBoundary>
                  }
                />
              }
              {
                isMobile && localStorage.getItem("textileMinistryId") === "M034" &&
                <Route
                  path="/workitem-sm/application"
                  render={(props) => <TextileApplication {...props} />}
                  children={
                    <ErrorBoundary>
                      <TextileApplication />
                    </ErrorBoundary>
                  }
                />
              }
              {
                isMobile && localStorage.getItem("textileMinistryId") === "M034" &&
                <Route
                  path="/workitem-sm"
                  render={(props) => <TextileVerification {...props} />}
                  children={
                    <ErrorBoundary>
                      <TextileVerification />
                    </ErrorBoundary>
                  }
                />
              }
              {
                isMobile && localStorage.getItem("textileMinistryId") === "M034" &&
                < Route
                  path="/"
                  render={
                    () => (<Redirect to="/workitem-sm" />)
                  }
                />
              }
              {
                (roleList.length !== 0) && !isRoleIdSelected &&
                <Route
                  path="/switchRole"
                  render={(props) => <SwitchRole {...props} />}
                  children={
                    <ErrorBoundary>
                      <SwitchRole
                        roleList={roleList}
                        setRoleList={setRoleList}
                        roleId={roleId}
                        setRoleId={setRoleId}
                        isRoleIdSelected={isRoleIdSelected}
                        setIsRoleIdSelected={setIsRoleIdSelected}
                      />
                    </ErrorBoundary>
                  }
                />}
              {
                (roleList.length !== 0) && !isRoleIdSelected && localStorage.getItem("roleId") === null &&
                < Route
                  // exact
                  path="/"
                  render={
                    () => (<Redirect to="/switchRole" />)
                  }
                />
              }
              <Route
                path="/forbidden"
                render={(props) => <Forbidden {...props} />}
                children={
                  <ErrorBoundary>
                    <Forbidden />
                  </ErrorBoundary>
                }
              />

              <div>
                <div className="fontSizeDisplay">
                  <Row className="margin-align">
                    <ContextContainer>

                      <Col
                        xs={2.5}
                        id="sidebar-wrapper"
                        className="sidebarWrapper"
                      >
                        <Sidebar showBackground={false} />
                      </Col>

                      <Col className="margin-align" id="page-content-wrapper">
                        {userProfile.msg !== "Unauthorized User" &&
                          <Row>
                            <div className="headerContent">
                              <Header />
                              <Header1
                                roleId={roleId}
                                roleList={roleList}
                                setRoleList={setRoleList}
                                isRoleIdSelected={isRoleIdSelected}
                                setIsRoleIdSelected={setIsRoleIdSelected}
                              />
                            </div>
                          </Row>
                        }
                        <Row className="margin-align">
                          <Col className="margin-align">

                            <Route
                              path="/dashboard"
                              render={(props) => <Dashboard {...props} />}
                              children={
                                <ErrorBoundary>
                                  <Dashboard
                                    setIsRoleIdSelected={setIsRoleIdSelected}
                                    setRoleId={setRoleId}
                                  />
                                </ErrorBoundary>
                              }
                            />
                            <Route
                              path="/application"
                              render={(props) => <Application {...props} />}
                              children={
                                <ErrorBoundary>
                                  <Application />
                                </ErrorBoundary>
                              }
                            />
                            <Route
                              path="/workitem"
                              render={(props) => <WorkItem {...props} />}
                              children={
                                <ErrorBoundary>
                                  <WorkItem />
                                </ErrorBoundary>
                              }
                            />
                            <Route
                              path="/Preview"
                              render={(props) => <Preview {...props} />}
                              children={
                                <ErrorBoundary>
                                  <Preview />
                                </ErrorBoundary>
                              }
                            />

                            <Route
                              path="/userCreation"
                              render={(props) => <UserCreation {...props} />}
                              children={
                                <ErrorBoundary>
                                  <UserCreation />
                                </ErrorBoundary>
                              }
                            />
                            <Route
                              path="/paymentReport"
                              render={(props) => <PaymentReport {...props} />}
                              children={
                                <ErrorBoundary>
                                  <PaymentReport />
                                </ErrorBoundary>
                              }
                            />
                            <Route
                              path="/userManage"
                              render={(props) => <UserManage {...props} />}
                              children={
                                <ErrorBoundary>
                                  <UserManage />
                                </ErrorBoundary>
                              }
                            />

                            <Route
                              path="/excelupload"
                              render={(props) => <Excelupload {...props} />}
                              children={
                                <ErrorBoundary>
                                  <Excelupload />
                                </ErrorBoundary>
                              }
                            />
                             <Route
                              path="/systemmasters"
                              render={(props) => <LicenseInsertion {...props} />}
                              children={
                                <ErrorBoundary>
                                  <LicenseInsertion />
                                </ErrorBoundary>
                              }
                            />

                            <Route
                              path="/workFlowManagement"
                              render={(props) => (
                                <WorkflowManagement {...props} />
                              )}
                              children={
                                <ErrorBoundary>
                                  <WorkflowManagement />
                                </ErrorBoundary>
                              }
                            />
                            <Route
                              path="/workflowCreation"
                              render={(props) => (
                                <WorkflowCreation {...props} />
                              )}
                              children={
                                <ErrorBoundary>
                                  <WorkflowCreation />
                                </ErrorBoundary>
                              }
                            />
                            <Route
                              path="/workflowView"
                              render={(props) => (
                                <WorkflowView {...props} />
                              )}
                              children={
                                <ErrorBoundary>
                                  <WorkflowView />
                                </ErrorBoundary>
                              }
                            />
                            <Route
                              path="/myDetail"
                              render={(props) => <MyDetail {...props} />}
                              children={
                                <ErrorBoundary>
                                  <MyDetail />
                                </ErrorBoundary>
                              }
                            />
                            <Route
                              path="/report"
                              render={(props) => <Report {...props} />}
                              children={
                                <ErrorBoundary>
                                  <Report />
                                </ErrorBoundary>
                              }
                            />
                            <Route
                              path="/department"
                              render={(props) => <Department {...props} />}
                              children={
                                <ErrorBoundary>
                                  <Department />
                                </ErrorBoundary>
                              }
                            />
                            <Route
                              path="/splitDocument"
                              render={(props) => <SplitDocument {...props} />}
                              children={
                                <ErrorBoundary>
                                  <SplitDocument />
                                </ErrorBoundary>
                              }
                            />
                            <Route
                              path="/ministry"
                              render={(props) => <Ministry {...props} />}
                              children={
                                <ErrorBoundary>
                                  <Ministry />
                                </ErrorBoundary>
                              }
                            />
                            <Route
                              path="/ComplianceReport"
                              render={(props) => (
                                <ComplianceReport {...props} />
                              )}
                              children={
                                <ErrorBoundary>
                                  <ComplianceReport />
                                </ErrorBoundary>
                              }
                            />

                            {/* <Route
                              path="/"
                              render={
                                () => {
                                  if (roleId) {
                                    if (roleId !== '6')
                                      return <Redirect to="/dashboard" />
                                    else
                                      return <Redirect to="/application" />
                                  }
                                  if (userProfile.msg !== "Unauthorized User")
                                    return <Redirect to="/dashboard" />
                                }
                              }
                            /> */}

                            <Route
                              path="*"
                              render={
                                () => {
                                  if (window.location.pathname === "/switchRole" ||
                                    window.location.pathname === "/login" ||
                                    window.location.pathname === "/" ||
                                    (
                                      //   (
                                      //   JSON.parse(localStorage.getItem("roleId")) === "3" ||
                                      //   JSON.parse(localStorage.getItem("roleId")) === "4" ||
                                      //   JSON.parse(localStorage.getItem("roleId")) === "5" ||
                                      //   JSON.parse(localStorage.getItem("roleId")) === "6" ||
                                      //   JSON.parse(localStorage.getItem("roleId")) === "7" ||
                                      //   JSON.parse(localStorage.getItem("roleId")) === "8" ||
                                      //   JSON.parse(localStorage.getItem("roleId")) === "9"
                                      // ) &&
                                      window.location.pathname === "/dashboard"
                                    ) ||
                                    ((JSON.parse(localStorage.getItem("roleId")) === "5" ||
                                      JSON.parse(localStorage.getItem("roleId")) === "6" ||
                                      JSON.parse(localStorage.getItem("roleId")) === "7" ||
                                      JSON.parse(localStorage.getItem("roleId")) === "9"
                                    ) &&
                                      window.location.pathname === "/application"
                                    ) ||
                                    (
                                      (JSON.parse(localStorage.getItem("roleId")) === "5" ||
                                        JSON.parse(localStorage.getItem("roleId")) === "6" ||
                                        JSON.parse(localStorage.getItem("roleId")) === "7" ||
                                        JSON.parse(localStorage.getItem("roleId")) === "8"
                                      ) &&
                                      window.location.pathname === "/department") ||
                                    window.location.pathname === "/workitem" ||
                                    window.location.pathname === "/paymentgateway/:id" ||
                                    window.location.pathname === "/investorDSC/:id" ||
                                    window.location.pathname === "/task" ||
                                    window.location.pathname === "/Preview" ||
                                    (window.location.pathname === "/userCreation" ||
                                      window.location.pathname === "/UserCreation") ||
                                    ((
                                      JSON.parse(localStorage.getItem("roleId")) === "5"
                                    ) &&
                                      window.location.pathname === "/paymentReport"
                                    ) ||
                                    (
                                      (JSON.parse(localStorage.getItem("roleId")) === "3" ||
                                        JSON.parse(localStorage.getItem("roleId")) === "5" ||
                                        JSON.parse(localStorage.getItem("roleId")) === "7"
                                      ) &&
                                      (window.location.pathname === "/userManage" ||
                                        window.location.pathname === "/UserManage")
                                    ) ||
                                    (
                                      (JSON.parse(localStorage.getItem("roleId")) === "3" ||
                                        JSON.parse(localStorage.getItem("roleId")) === "10"
                                      ) &&
                                      window.location.pathname === "/excelupload"
                                    ) ||
                                    ((
                                      JSON.parse(localStorage.getItem("roleId")) === "3"
                                    ) &&
                                      window.location.pathname === "/systemmasters"
                                    ) ||
                                    window.location.pathname === "/MISReport" ||
                                    (
                                      (JSON.parse(localStorage.getItem("roleId")) === "3" ||
                                        JSON.parse(localStorage.getItem("roleId")) === "5" ||
                                        JSON.parse(localStorage.getItem("roleId")) === "7"
                                      ) &&
                                      window.location.pathname === "/workFlowManagement"
                                    ) ||
                                    window.location.pathname === "/workflowCreation" ||
                                    window.location.pathname === "/workflowView" ||
                                    ((
                                      JSON.parse(localStorage.getItem("roleId")) === "3" ||
                                      JSON.parse(localStorage.getItem("roleId")) === "4" ||
                                      JSON.parse(localStorage.getItem("roleId")) === "5" ||
                                      JSON.parse(localStorage.getItem("roleId")) === "6" ||
                                      JSON.parse(localStorage.getItem("roleId")) === "7" ||
                                      JSON.parse(localStorage.getItem("roleId")) === "8" ||
                                      JSON.parse(localStorage.getItem("roleId")) === "9"
                                    )
                                      &&
                                      window.location.pathname === "/myDetail"
                                    ) ||
                                    ((JSON.parse(localStorage.getItem("roleId")) === "3" ||
                                      JSON.parse(localStorage.getItem("roleId")) === "5" ||
                                      JSON.parse(localStorage.getItem("roleId")) === "6" ||
                                      JSON.parse(localStorage.getItem("roleId")) === "7" ||
                                      JSON.parse(localStorage.getItem("roleId")) === "9"
                                    ) &&
                                      window.location.pathname === "/report"
                                    ) ||
                                    window.location.pathname === "/splitDocument" ||
                                    ((
                                      JSON.parse(localStorage.getItem("roleId")) === "6"
                                    ) &&
                                      window.location.pathname === "/ComplianceReport"
                                    ) ||
                                    (
                                      (
                                        JSON.parse(localStorage.getItem("roleId")) === "3" ||
                                        JSON.parse(localStorage.getItem("roleId")) === "4" ||
                                        JSON.parse(localStorage.getItem("roleId")) === "5" ||
                                        JSON.parse(localStorage.getItem("roleId")) === "6" ||
                                        JSON.parse(localStorage.getItem("roleId")) === "7" ||
                                        JSON.parse(localStorage.getItem("roleId")) === "8" ||
                                        JSON.parse(localStorage.getItem("roleId")) === "9"
                                      )
                                      &&
                                      window.location.pathname === "/ministry"
                                    )
                                  ) {
                                    console.log(JSON.parse(localStorage.getItem("roleId")), window.location.pathname, "vbfdjv das")
                                    if (JSON.parse(localStorage.getItem("roleId")) === "6") {
                                      return <Redirect to="/application" />
                                    }
                                    return <Redirect to="/dashboard" />
                                  } else {
                                    // alert("The page is forbidden")
                                    console.log(JSON.parse(localStorage.getItem("roleId")), window.location.pathname, "vbfdjv")
                                    return <Redirect to="/forbidden" />
                                  }
                                }
                              }
                            />

                          </Col>
                        </Row>
                        <hr />
                        <Row className="margin-align">
                          <Footer />
                        </Row>
                      </Col>
                    </ContextContainer>
                  </Row>
                </div>
              </div>
            </Switch>
          </RenderOnAuthenticated>
        </div>
      </Suspense>
    </Router>
  );
}

export default App;
